<template lang="">
<div>
    <Navbar />
    <div id="Proje_ElektrikliAracSarj_Carport">
        <section class="page-title page-title-1" id="page-title">
            <div class="page-title-wrap bg-overlay bg-overlay-dark-2">
                <div class="bg-section"><img :src="require('../../../../assets/gt/elektriklisarjistasyon.jpg')" alt="Background" /></div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            <div class="title">
                                <h1 class="title-heading">Elektrikli Araç Şarj - Carport</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="breadcrumb-wrap">
                <div class="container">
                    <ol class="breadcrumb d-flex">
                        <li class="breadcrumb-item">
                            <router-link to="/tr/Anasayfa">Ana Sayfa</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page"><a href="#">Elektrikli Araç Şarj - Carport</a></li>
                    </ol>
                </div>
            </div>
        </section>

        <section class="service-single" id="service-single">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-4 order-1">
                        <div class="sidebar sidebar-service">
                            <div class="widget widget-services">
                                <div class="widget-title">
                                    <h5>Projelerimiz</h5>
                                </div>
                                <div class="widget-content">
                                    <ul class="list-unstyled">
                                        <li>
                                            <router-link to="/tr/Projeler/BagEvi_HobiEvi"><span>Bağ Evi - Hobi Evi</span><i class="energia-arrow-right"></i></router-link>
                                        </li>
                                        <li>
                                            <router-link to="/tr/Projeler/ElektrikliAracSarj_Carport"><span>Elektrikli Araç Şarj - Carport</span><i class="energia-arrow-right"></i></router-link>
                                        </li>
                                        <li>
                                            <router-link to="/tr/Projeler/Konut_Mesken"><span>Konut - Mesken</span><i class="energia-arrow-right"></i></router-link>
                                        </li>
                                        <li>
                                            <router-link to="/tr/Projeler/SehirMobilyalari"><span>Şehir Mobilyaları</span><i class="energia-arrow-right"></i></router-link>
                                        </li>
                                        <li>
                                            <router-link to="/tr/Projeler/Tir_Karavan"><span>Tır - Karavan</span><i class="energia-arrow-right"></i></router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-8 order-0 order-lg-2 mt-5">
                        <div class="service-entry">
                            <div class="entry-content">
                                <div class="entry-introduction entry-infos">
                                    <h5>Elektrikli Araç Şarj - Carport</h5>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-12 col-md-3 col-lg-2">
                                                <div class="award">
                                                    <div class="award-img img-circle zoom"><img :src="require('../../../../assets/gt/Projeler/ElektrikliAracSarj_Carport/3.jpg')" alt="Proje img" /></div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-3 col-lg-2">
                                                <div class="award">
                                                    <div class="award-img img-circle zoom"><img :src="require('../../../../assets/gt/Projeler/ElektrikliAracSarj_Carport/4.jpg')" alt="Proje img" /></div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-3 col-lg-2">
                                                <div class="award">
                                                    <div class="award-img img-circle zoom"><img :src="require('../../../../assets/gt/Projeler/ElektrikliAracSarj_Carport/6.jpg')" alt="Proje img" /></div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-3 col-lg-2">
                                                <div class="award">
                                                    <div class="award-img img-circle zoom"><img :src="require('../../../../assets/gt/Projeler/ElektrikliAracSarj_Carport/7.jpg')" alt="Proje img" /></div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-3 col-lg-2">
                                                <div class="award">
                                                    <div class="award-img img-circle zoom"><img :src="require('../../../../assets/gt/Projeler/ElektrikliAracSarj_Carport/8.jpg')" alt="Proje img" /></div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-3 col-lg-2">
                                                <div class="award">
                                                    <div class="award-img img-circle zoom"><img :src="require('../../../../assets/gt/Projeler/ElektrikliAracSarj_Carport/9.jpg')" alt="Proje img" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
    <Footer />
</div>
</template>

<script>
import Navbar from "../../SubPages-TR/navbar.vue";
import Footer from "../../SubPages-TR/Footer.vue";
export default {
    components: {
        Navbar,
        Footer
    },
    mounted() {
        (function ($) {
            "use strict";
            var $bgSection = $(".bg-section");
            var $bgPattern = $(".bg-pattern");
            var $colBg = $(".col-bg");
            $bgSection.each(function () {
                var bgSrc = $(this).children("img").attr("src");
                var bgUrl = 'url(' + bgSrc + ')';
                $(this).parent().css("backgroundImage", bgUrl);
                $(this).parent().addClass("bg-section");
                $(this).remove();
            });
            $bgPattern.each(function () {
                var bgSrc = $(this).children("img").attr("src");
                var bgUrl = 'url(' + bgSrc + ')';
                $(this).parent().css("backgroundImage", bgUrl);
                $(this).parent().addClass("bg-pattern");
                $(this).remove();
            });
            $colBg.each(function () {
                var bgSrc = $(this).children("img").attr("src");
                var bgUrl = 'url(' + bgSrc + ')';
                $(this).parent().css("backgroundImage", bgUrl);
                $(this).parent().addClass("col-bg");
                $(this).remove();
            });
            var $moduleSearch = $(".module-icon-search"),
                $searchWarp = $(".module-search-warp");
            $moduleSearch.on("click", function () {
                $(this).parent().addClass("module-active");
                $(this).parent().siblings().removeClass("module-active");
                $searchWarp.addClass("search-warp-active");
            });
            var $moduleCart = $(".module-icon-cart"),
                $cartWarp = $(".module-cart-warp");
            $moduleCart.on("click", function () {
                $(this).parent().toggleClass("module-active");
                $(this).parent().siblings().removeClass("module-active");
            });
            var $module = $(".module"),
                $moduleWarp = $(".module-warp"),
                $moduleCancel = $(".module-cancel");
            $moduleCancel.on("click", function (e) {
                $module.removeClass("module-active");
                $searchWarp.removeClass("search-warp-active");
                e.stopPropagation();
                e.preventDefault();
            });
            $(document).keyup(function (e) {
                if (e.key === "Escape") {
                    $module.removeClass("module-active");
                    $moduleWarp.removeClass("active");
                    $searchWarp.removeClass("search-warp-active");
                }
            });
            var $w = $(window);
            var $wWidth = $w.width();
            var mobile_resolution_size = "1200";
            var $dropToggle = $("[data-toggle='dropdown']");
            $dropToggle.on("click", function (event) {
                $(this).each(() => {
                    if ($wWidth <= mobile_resolution_size && $(this).attr('href') === '#') {
                        event.preventDefault();
                        event.stopPropagation();
                        $(this).parent().siblings().removeClass("show");
                        $(this).parent().toggleClass("show");
                    } else if ($wWidth <= mobile_resolution_size && !$(this).attr('href') !== '#') {
                        event.preventDefault();
                        event.stopPropagation();
                        $(this).parent().siblings().removeClass("show");
                        $(this).parent().toggleClass("show");
                        $(this).children('span').on('click', () => {
                            window.location.href = $(this).attr('href');
                        })
                    }
                })
            });
            $(window).scroll(function () {
                if ($(document).scrollTop() > 100) {
                    $('.navbar-sticky').addClass('navbar-fixed');
                } else {
                    $('.navbar-sticky').removeClass('navbar-fixed');
                }
            });
            
            $('#campaignmonitor').submit(function (e) {
                e.preventDefault();
                $.getJSON(this.action + "?callback=?", $(this).serialize(), function (data) {
                    if (data.Status === 400) {
                        alert("Error: " + data.Message);
                    } else {
                        alert("Success: " + data.Message);
                    }
                });
            });
            var $carouselDirection = $("html").attr("dir");
            var $carouselrtl = null;
            if ($carouselDirection == "rtl") {
                $carouselrtl = true;
            } else {
                $carouselrtl = false;
            }
            $(".carousel").each(function () {
                var $Carousel = $(this);
                $Carousel.owlCarousel({
                    loop: $Carousel.data('loop'),
                    autoplay: $Carousel.data("autoplay"),
                    margin: $Carousel.data('space'),
                    nav: $Carousel.data('nav'),
                    dots: $Carousel.data('dots'),
                    dotsSpeed: $Carousel.data('speed'),
                    mouseDrag: $Carousel.data('drag'),
                    touchDrag: $Carousel.data('drag'),
                    pullDrag: $Carousel.data('drag'),
                    rtl: $carouselrtl,
                    responsive: {
                        0: {
                            items: 1,
                        },
                        768: {
                            items: $Carousel.data('slide-rs'),
                        },
                        1000: {
                            items: $Carousel.data('slide'),
                            center: $Carousel.data('center'),
                        }
                    }
                });
            });
            $(".slider-carousel").each(function () {
                var $Carousel = $(this);
                $Carousel.owlCarousel({
                    loop: $Carousel.data('loop'),
                    autoplay: $Carousel.data("autoplay"),
                    margin: $Carousel.data('space'),
                    nav: $Carousel.data('nav'),
                    dots: $Carousel.data('dots'),
                    center: $Carousel.data('center'),
                    dotsSpeed: $Carousel.data('speed'),
                    rtl: $carouselrtl,
                    responsive: {
                        0: {
                            items: 1,
                        },
                        768: {
                            items: $Carousel.data('slide-rs'),
                        },
                        1000: {
                            items: $Carousel.data('slide'),
                        }
                    },
                    animateOut: 'fadeOut',
                });
            });
           
            $(".process-content-carousel").on('changed.owl.carousel', function (event) {
                var items = event.item.count;
                var item = event.item.index;
                $(".process-image-carousel").trigger('to.owl.carousel', [item, 800]);
            })
            $('.entry-processes .images-holder .process-image-carousel').on('changed.owl.carousel', function (event) {
                var items = event.item.count;
                var item = event.item.index;
                $(".entry-processes .entry-body .process-content-carousel").trigger('to.owl.carousel', [item, 800]);
            })
            var backTop = $('#back-to-top');
            if (backTop.length) {
                var scrollTrigger = 600,
                    backToTop = function () {
                        var scrollTop = $(window).scrollTop();
                        if (scrollTop > scrollTrigger) {
                            backTop.addClass('show');
                        } else {
                            backTop.removeClass('show');
                        }
                    };
                backToTop();
                $(window).on('scroll', function () {
                    backToTop();
                });
                backTop.on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                        scrollTop: 0
                    }, 700);
                });
            }
            var $projectFilter = $(".projects-filter"),
                projectLength = $projectFilter.length,
                protfolioFinder = $projectFilter.find("a"),
                $projectAll = $("#projects-all");
            protfolioFinder.on("click", function (e) {
                e.preventDefault();
                $projectFilter.find("a.active-filter").removeClass("active-filter");
                $(this).addClass("active-filter");
            });
            if (projectLength > 0) {
                $projectAll.imagesLoaded().progress(function () {
                    $projectAll.isotope({
                        filter: "*",
                        animationOptions: {
                            duration: 750,
                            itemSelector: ".project-item",
                            easing: "linear",
                            queue: false,
                        }
                    });
                });
            }
            protfolioFinder.on("click", function (e) {
                e.preventDefault();
                var $selector = $(this).attr("data-filter");
                $projectAll.imagesLoaded().progress(function () {
                    $projectAll.isotope({
                        filter: $selector,
                        animationOptions: {
                            duration: 750,
                            itemSelector: ".project-item",
                            easing: "linear",
                            queue: false,
                        }
                    });
                    return false;
                });
            });
            var aScroll = $('a[data-scroll="scrollTo"]');
            aScroll.on('click', function (event) {
                var target = $($(this).attr('href'));
                if (target.length) {
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    if ($(this).hasClass("menu-item")) {
                        $(this).parent().addClass("active");
                        $(this).parent().siblings().removeClass("active");
                    }
                }
            });
            $(".progressbar").each(function () {
                $(this).waypoint(function () {
                    var progressBar = $(".progress-bar"),
                        progressBarTitle = $(".progress-title .value");
                    progressBar.each(function () {
                        $(this).css("width", $(this).attr("aria-valuenow") + "%");
                    });
                    progressBarTitle.each(function () {
                        $(this).css('opacity', 1);
                    });
                }, {
                    triggerOnce: true,
                    offset: 'bottom-in-view'
                });
            });
            siteFooter();
            $(window).resize(function () {
                siteFooter();
            });

            function siteFooter() {
                var siteContent = $('#wrapperParallax');
                var siteFooter = $('#footerParallax');
                var siteFooterHeight = siteFooter.height();
                siteContent.css({
                    "margin-bottom": siteFooterHeight
                });
            };
            // $('select').niceSelect();
            $('.collapse').on('shown.bs.collapse', function () {
                $(this).parent('.card').addClass('active-acc');
            });
            $('.collapse').on('hidden.bs.collapse', function () {
                $(this).parent('.card').removeClass('active-acc');
            });
            $("#loadMore").on("click", function (e) {
                e.preventDefault();
                $(".content.d-none").slice(0, 3).removeClass('d-none');
                if ($(".content.d-none").length == 0) {
                    $("#loadMore").addClass("d-none");
                }
            })
            var imagePointer = $('.img-hotspot .img-hotspot-pointer');
            var pointerInfo = $('.img-hotspot .img-hotspot-pointer .info');
            pointerInfo.each(function (index) {
                $(this).css('top', $(this).data('info-y'));
                $(this).css('left', $(this).data('info-x'));
            });
            $('.product-quantity span ').on('click', 'a.plus, a.minus', function () {
                var qty = $(this).parents('.product-quantity').find('.pro-qunt');
                var val = parseFloat(qty.val());
                var max = parseFloat(qty.data('max'));
                var min = parseFloat(qty.data('min'));
                var step = parseFloat(qty.data('step'));
                if (isNaN(val)) {
                    val = 0;
                }
                if ($(this).is('.plus')) {
                    if (max && (max <= val)) {
                        qty.val(max);
                    } else {
                        qty.val(val + step);
                    }
                } else {
                    if (min && (min >= val)) {
                        qty.val(min);
                    } else if (val > 1) {
                        qty.val(val - step);
                    }
                }
            });
        }(window.jQuery));
    },
}
</script>

<style scoped>
@import "../../../../assets/css/vendor.min.css";
@import "../../../../assets/css/style.css";
@import "../../../../assets/css/app.css";

.gallery {
    margin-left: 3vw;
    margin-right: 3vw;
    z-index: 9999;
}

.zoom {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.zoom:hover,
.zoom:active,
.zoom:focus {
    -ms-transform: scale(4.5);
    -moz-transform: scale(4.5);
    -webkit-transform: scale(4.5);
    -o-transform: scale(4.5);
    transform: scale(4.5);
    position: relative;
    z-index: 99999;
}

@media only screen and (max-width: 768px) {
    .gallery {
        margin-left: 15vw;
        margin-right: 15vw;
        z-index: 99999;
    }

    .DivName {
        cursor: pointer
    }
}
</style>
